<template>
  <div class="products">
    <a
      class="section-vision"
      href="https://pangdangjia.tmall.com/?spm=a220o.1000855.w5001-23402521143.2.6ac01d20KvzCQw&scene=taobao_shop"
      target="_black"
    ></a>
    <div class="section-goods row-center">
      <div class="section-box goods">
        <div class="goods-head">
          <div class="icon"></div>
          <h1 class="title">
            <strong>胖当家系列产品</strong>
            <span>让中国高级料理走进千家万户</span>
          </h1>
        </div>
        <div class="goods-body">
          <a
            class="item"
            v-for="(item, index) in list"
            :key="index"
            href="https://pangdangjia.tmall.com/?spm=a220o.1000855.w5001-23402521143.2.6ac01d20KvzCQw&scene=taobao_shop"
            target="_black"
          >
            <div class="item-head">
              <strong>{{ item.title }}</strong>
              <em>{{ item.description }}</em>
              <span v-html="item.content"></span>
            </div>
            <div class="item-body">
              <img :src="item.image" alt="" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Products",
  data() {
    return {
      list: [
        {
          title: "调味系列品",
          description: "下饭行家，告别寡淡餐餐对味",
          content:
            "系列产品：</br>大块牛肉酱；山珍酱</br>蒜头油；葱珠油</br>柠檬凤爪调料包</br>潮式卤味调料包</br>凉拌料包",
          image: require("@/assets/products/products1.jpg")
        },
        {
          title: "卤制系列品",
          description: "一口难忘，放松休闲随心享",
          content: "系列产品：</br>泰式柠檬无骨鸡爪</br>柠檬油柑无骨鸡爪</br>潮式卤味",
          image: require("@/assets/products/products2.jpg")
        },
        {
          title: "预制菜系列",
          description: "灵魂一人食，健康简餐不二选",
          content: "系列产品：</br>爽滑美味粤式肠粉</br>劲弹爆筋五香牛肉</br>",
          image: require("@/assets/products/products3.jpg")
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .products {
    background: #fff;
    .section-vision {
      display: block;
      background: url("../../assets/products/vision-m.jpg") no-repeat center center;
      background-size: cover;
      width: 100%;
      height: 4rem;
    }
    .section-goods {
      background: #fff;
      padding: 0.6rem;
      box-sizing: border-box;
      .goods {
        display: flex;
        flex-direction: column;
        &-head {
          .icon {
            display: none;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0 0 0.3rem 0;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 0.36rem;
              color: #e76d01;
              line-height: 0.5rem;
            }
            & > span {
              font-family: PingFangSC-Light;
              font-size: 0.24rem;
              color: #e76d01;
              line-height: 0.4rem;
            }
          }
        }
        &-body {
          margin-top: 0.3rem;
          display: flex;
          flex-direction: column;
          .item {
            width: 100%;
            height: 2.5rem;
            border-radius: 0.12rem;
            overflow: hidden;
            margin-bottom: 0.22rem;
            position: relative;
            &-head {
              background-image: linear-gradient(90deg, rgba(231, 109, 1, 0.04) 10%, #e76d01 73%);
              position: absolute;
              right: 0;
              top: 0;
              z-index: 10;
              width: 3.75rem;
              height: 100%;
              padding-left: 1rem;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              overflow: hidden;
              & > strong {
                font-family: PingFangSC-Medium;
                font-size: 0.32rem;
                color: #ffffff;
              }
              & > span,
              & > em {
                display: none;
              }
              &::after {
                content: "";
                width: 0.6rem;
                height: 0.02rem;
                background: #fff;
                margin-top: 0.16rem;
              }
            }
            &-body {
              width: 100%;
              height: 100%;
              & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 750px) {
  .products {
    background: #fff;
    .section-vision {
      display: block;
      background: url("../../assets/products/vision.jpg") no-repeat center center;
      background-size: cover;
      min-width: 1190px;
      height: 500px;
    }
    .section-goods {
      background: #fff;
      padding: 150px 0 120px;
      box-sizing: border-box;
      .goods {
        display: flex;
        flex-direction: column;
        &-head {
          padding-top: 13px;
          box-sizing: border-box;
          .icon {
            background: url("../../assets/products/products0.png") no-repeat center;
            background-size: 100%;
            width: 277px;
            height: 90px;
            margin-bottom: 10px;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0 0 60px;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 32px;
              color: #e76d01;
              line-height: 32px;
            }
            & > span {
              display: none;
            }
          }
        }
        &-body {
          margin-top: 60px;
          display: flex;
          flex-direction: column;
          .item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 30px;
            &:nth-child(2n) {
              flex-direction: row-reverse;
            }
            &-head {
              flex: 0 0 auto;
              width: 350px;
              padding: 0 40px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              overflow: hidden;
              & > strong {
                font-family: PingFangSC-Medium;
                font-size: 32px;
                color: #e76d00;
                line-height: 36px;
                margin-bottom: 20px;
                text-align: left;
              }
              & > span,
              & > em {
                font-family: PingFangSC-Light;
                font-size: 20px;
                color: #333333;
                line-height: 40px;
                text-align: left;
              }
              & > em {
                font-weight: 700;
                font-style: normal;
              }
            }
            &-body {
              flex: 0 0 auto;
              width: 800px;
              height: 460px;
              border-radius: 6px;
              overflow: hidden;
              & > img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
